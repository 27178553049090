import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import styled from "styled-components"
import Layout from "../../components/layout"
import EventList from "../../components/event/EventList"
import SEO from "../../components/seo"
import { Container, Heading, Text } from "../../components/styled/Styled"
import { MEDIA_QUERIES, COLORS } from "../../constants"

const ImgContainer = styled(motion.div)`
  width: 40%;
  z-index: -2;
  padding-top: 2rem;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 90%;
    margin: auto;
    padding-top: 2rem;
  }
`

const Section = styled(motion.div)`
  display: flex;
  border-right: 8px solid ${COLORS.BLACK};
  flex-wrap: wrap;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column-reverse;
    border-right: none;
    margin: auto;
  }
`

const Article = styled(motion.div)`
  display: flex;
  width: 60%;
  flex-direction: column;
  padding: 0 3rem;
  text-align: right;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding: 0 1rem;
    text-align: center;
  }
`
const StyledSubheading = styled(motion.div)`
  margin-right: 1rem;
  text-align: right;
  width: 100%;
  color: ${COLORS.PRIMARY};
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    display: none;
  }
`

const headingVar = {
  initial: { opacity: 0, x: "-300px" },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 2,
      delay: 1,
      type: "spring",
      stiffness: 40,
    },
  },
}

const textVar = {
  initial: { opacity: 0, x: "-300px" },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 2,
      delay: 1.2,
      type: "spring",
      stiffness: 40,
    },
  },
}

const imgVar = {
  initial: { opacity: 0, y: "-300px" },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 2, type: "spring", stiffness: 40 },
  },
}

const articleVar = {
  initial: { opacity: 0, y: "-300px" },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 2, type: "spring", stiffness: 40 },
  },
}

const sectionVar = {
  initial: { opacity: 0, y: "300px" },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 2, type: "spring", stiffness: 40 },
  },
}

const EventsPage = () => {
  const { events } = useStaticQuery(graphql`
    query {
      events: file(relativePath: { eq: "anke-events.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Events" />

      <Container>
        <Section>
          <StyledSubheading>EVENTS</StyledSubheading>
          <Article initial="initial" animate="animate" variants={articleVar}>
            <Heading initial="initial" animate="animate" variants={headingVar}>
              UPCOMING EVENTS
            </Heading>
            <Text initial="initial" animate="animate" variants={textVar}>
              Organising is in my blood. I just love it! Each year I organise
              (myself or in collab with an incredible team) several workshops,
              fundraisers, closet sales, and shows.
            </Text>
            <Text initial="initial" animate="animate" variants={textVar}>
              3 years ago I joined the ‘Hiphop Unite Belgium’-family as an
              organisor. Each year we organise the Belgian Championship where
              duo’s, small crews, and megacrews battle for a place in the
              Belgian TOP 5.
            </Text>
            <Text>
              In 2020 we were planning on organising the Hiphop Unite allstyle
              battles. These battles would be 1-1 AND 5-5, but we had to
              postpone these battles to 2021 due to COVID measures.
            </Text>
            <Text>
              During the quarantine my collective and me organised a
              ‘funkstyles’ online competition lockdown and we are planning to
              repeat this in real life in 2021.
            </Text>
          </Article>
          <ImgContainer
            initial="initial"
            animate="animate"
            variants={imgVar}
            style={{ marginTop: "auto" }}
          >
            <Img fluid={events.childImageSharp.fluid} />
          </ImgContainer>
        </Section>
        <Section style={{ paddingTop: "4rem" }}>
          <motion.div initial="initial" animate="animate" variants={sectionVar}>
            <EventList />
          </motion.div>
        </Section>
      </Container>
    </Layout>
  )
}

export default EventsPage
